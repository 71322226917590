import React from "react";
import { Grid } from "@mui/material";
import useTranslation from "../hooks/useTranslation";


const Privacy: React.FC = () => {
  const t = useTranslation("pages", "Privacy");
  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item md={6} xs={12}>
        <h1>{t("privacy_policy_hl")}</h1>

        <p>
          {" "}
          {t("privacy_policy_txt")}
        </p>
        <h2>{t("info_collected_hl")}</h2>
        <p>{t("info_collected_txt")}</p>
        <h2>{t("info_use_hl")}</h2>
        <p>{t("info_use_p1")}</p>
        <p>{t("info_use_p2")}</p>
        <h2>{t("cookies_hl")}</h2>
        <p>{t("cookies_p1")}</p>
        <p>{t("cookies_p2")}</p>
        <h2>{t("3rd_hl")}</h2>
        <p>
          {" "}
          {t("3rd_p")}
        </p>
        <h2>{t("control_pi_hl")}</h2>
        <p>{t("control_pi_p1")}</p>
        <p>
          {" "}
          {t("control_pi_p2")}
        </p>
        <p>{t("control_pi_p3")}</p>
      </Grid>
    </Grid>
  );
};

export default Privacy;
