import App from "./es/pages/App.json";
import Search from "./es/pages/Search.json";
import Car from "./es/components/Car.json";
import Footer from "./es/components/Footer.json";
import Terms from "./es/pages/Terms.json";
import Privacy from "./es/pages/Privacy.json";

const translations = { pages: { App, Search, Terms, Privacy}, components: { Car, Footer } };

export default translations;
