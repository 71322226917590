const AmazonProducts = [
  {
    name: "Luz de Emergencia AUTÓNOMA",
    image:
      "https://m.media-amazon.com/images/I/51jw7Ulg9HS._AC_UX300_UY300_SR300,300_.jpg",
    price: 39.5,
    link: "https://amzn.to/3lual8p",
  },
  {
    name: "Xiaomi Bomba de Aire Portátil",
    image: "https://m.media-amazon.com/images/I/31oO38N5TbL._AC_SY580_.jpg",
    price: 39.99,
    link: "https://amzn.to/3BunPGB",
  },
  {
    name: "Funda para Perros, Cubierta de Asiento para Perros, Funda de Asiento para Perros, Cojín de Picnic, Tamaño Universal",
    image: "https://m.media-amazon.com/images/I/51LlwlSvyCL._AC_SY580_.jpg",
    price: 29.99,
    link: "https://amzn.to/3DuNZJY",
  },
  {
    name: "Sisbrill 361 Todo Uso Coche",
    image: "https://m.media-amazon.com/images/I/31V488beLDL._AC_SY580_.jpg",
    price: 7.09,
    link: "https://amzn.to/3FxCrYk",
  },
];

export default AmazonProducts;
