import React from "react";
import { FormGroup, Box, Grid, InputBase, IconButton } from "@mui/material";
import { Skeleton } from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineOppositeContent,
  TimelineContent,
  TimelineConnector,
} from "@mui/lab";

import { Helmet } from "react-helmet";

import { Search as SearchIcon, Close as CloseIcon } from "@mui/icons-material";
import { useLazyQuery } from "@apollo/client";
import { Car } from "../graphql/queries/carqudb";

import {
  CarByVIN,
  CarByVINVars,
  CarByVINData,
} from "../graphql/queries/carqudb";

import useTranslation from "../hooks/useTranslation";

import CarComponent from "../components/Car";
import TransactionList from "../components/TransactionList";
import AmazonAffiliate from "../components/AmazonAffiliate";

const Search: React.FC = () => {
  const [value, setValue] = React.useState<undefined | string>();
  const [data, setData] = React.useState<Car[] | undefined>();
  const [loadCarsByVIN, { error, loading }] = useLazyQuery<
    CarByVINData,
    CarByVINVars
  >(CarByVIN, { onCompleted: (resultQuery) => setData(resultQuery?.carqudb) });

  const t = useTranslation("pages", "Search");

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setValue(event.target.value);
  };

  const search = (event: React.FormEvent) => {
    event.preventDefault();

    if (value !== undefined) {
      loadCarsByVIN({
        variables: { vin: value.toUpperCase() },
      });
    }
  };

  return (
    <Grid container direction="row" justifyContent="center" spacing={3}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("title")}</title>
      </Helmet>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <FormGroup row>
            <form onSubmit={search}>
              <InputBase
                placeholder={t("input-placeholder")}
                inputProps={{ "aria-label": "search VIN" }}
                defaultValue={value}
                onChange={handleSearchChange}
              />
              <IconButton type="submit" aria-label="search" size="large">
                <SearchIcon />
              </IconButton>
              <IconButton type="reset" aria-label="reset" size="large">
                <CloseIcon />
              </IconButton>
            </form>
          </FormGroup>
          {error && <Box>{error.message}</Box>}
        </Box>
      </Grid>

      {(data || loading) && (
        <>
          <Grid item md={6} xs={12}>
            <CarComponent
              car={data !== undefined ? data[0] : undefined}
              loading={loading}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Timeline>
              {loading &&
                [...Array(7)].map((index: number) => {
                  return (
                    <TimelineItem key={index}>
                      <TimelineOppositeContent>
                        <Skeleton variant="text" />
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Skeleton variant="text" />
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              {data?.map((car, myIndex, accumulator) => {
                if (myIndex > 0)
                  return (
                    <TransactionList
                      key={car.id}
                      car={car}
                      prevCar={accumulator[myIndex - 1]}
                    />
                  );
                return <TransactionList car={car} />;
              })}
            </Timeline>

            <AmazonAffiliate />
          </Grid>
        </>
      )}

      {/* Empty page */}
      {!data && !loading && (
        <Grid item md={6} xs={12}>
          <h1>{t("h1_1")}</h1>
          <ul>
            <li>{t("list1_item1")}</li>
            <li>{t("list1_item2")}</li>
            <li>{t("list1_item3")}</li>
            <li>{t("list1_item4")}</li>
            <li>{t("list1_item5")}</li>
          </ul>
          <p>{t("p_1")}</p>
          <p>{t("p_2")}</p>
          <h2>{t("h2_1")}</h2>
          <p>{t("p_3")}</p>
          <p>{t("p_4")}</p>
          <p>{t("p_5")}</p>
          <p>{t("p_6")}</p>
          <h3>{t("h_vinaudi")}</h3>
          <p>{t("p_vinaudi")}</p>
          <h3>{t("h_vinseat")}</h3>
          <p>{t("p_vinseat")}</p>
          <h3>{t("h_vinbmw")}</h3>
          <p>{t("p_vinbmw")}</p>
          <h3>{t("h_vinopel")}</h3>
          <p>{t("p_vinopel")}</p>
          <h3>{t("h_vinskoda")}</h3>
          <p>{t("p_vinskoda")}</p>
          <h3>{t("h_vinkawasaki")}</h3>
          <p>{t("p_vinkawasaki")}</p>
          <h3>{t("h_vinford")}</h3>
          <p>{t("p_vinford")}</p>
          <h3>{t("h_vinrenault")}</h3>
          <p>{t("p_vinrenault")}</p>
          <h3>{t("h_vinktm")}</h3>
          <p>{t("p_vinktm")}</p>
          <h3>{t("h_vinmercedes")}</h3>
          <p>{t("p_vinmercedes")}</p>
          <h3>{t("h_vincitroen")}</h3>
          <p>{t("p_vincitroen")}</p>
          <h3>{t("h_vintoyota")}</h3>
          <p>{t("p_vintoyota")}</p>
          <h3>{t("h3_novinshare")}</h3>
          <p>{t("p_novin")}</p>
          <ul>
            <li>{t("list_novin_1")}</li>
            <li>{t("list_novin_2")}</li>
            <li>{t("list_novin_3")}</li>
          </ul>
        </Grid>
      )}
    </Grid>
  );
};

export default Search;
