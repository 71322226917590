/** This is a custom hook that handles the translations namespace and prefix
 * Usage:
 * const t = useTranslation("pages", "Search");
 *
 * <div>{t("input-placeholder")}</div>
 */

import { useTranslation as I18nUseTranslation } from "react-i18next";

/**
 * @params ( namespace: string, prefix: string)
 * @return (key: string) => string
 *
 * example:
 * t = useTranslation("pages", "Search")
 * t("translation key") => "Translation text"
 */
function useTranslation(
  namespace: string,
  prefix: string
): (key: string) => string {
  const { t } = I18nUseTranslation([namespace]);

  return (key: string) => t(`${prefix}.${key}`);
}

export default useTranslation;
