import React from "react";
import { Grid } from "@mui/material";
import useTranslation from "../hooks/useTranslation";

const Terms: React.FC = () => {
  
  const t = useTranslation("pages", "Terms");

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item md={6} xs={12}>
        <h1>{t("terms-headline")}</h1>
      </Grid>
    </Grid>
  );
};

export default Terms;
