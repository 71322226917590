import App from "./es/pages/App.json";
import Search from "./en/pages/Search.json";
import Car from "./en/components/Car.json";
import Footer from "./en/components/Footer.json";
import Terms from "./en/pages/Terms.json";
import Privacy from "./en/pages/Privacy.json";

const translations = { pages: { App, Search, Terms, Privacy,  }, components: { Car, Footer } };

export default translations;
