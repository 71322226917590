import React from "react";
import {
  Card,
  Grid,
  CardMedia,
  CardContent,
  Link,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import products from "../constants/AmazonProducts";

import price from "../utils/price";

interface ProductProps {
  name: string;
  image: string;
  price: number;
  link: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    media: {
      height: 200,
      width: 200,
      margin: "0 auto",
    },
    card: {
      width: "60%",
      margin: "0 auto",
      justifyContent: "center",
    },
    title: {
      "& span": {
        fontSize: "14px",
      },
    },
    text: {
      fontSize: "14px",
      textDecoration: "none",
    },
  })
);

const Product: React.FC<ProductProps> = (product) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} alignContent="center">
      <Card className={classes.card}>
        <a href={product.link} style={{ textDecoration: "none" }}>
          <CardMedia image={product.image} className={classes.media} />
          <CardContent>
            <Typography className={classes.title}>{product.name} </Typography>
            <Link component="span" className={classes.text}>
              Precio: {price.format(product.price)}
            </Link>
          </CardContent>
        </a>
      </Card>
    </Grid>
  );
};

const AmazonAffiliate: React.FC = () => {
  return (
    <Grid container spacing={4} justifyContent="center">
      {products.map((product) => (
        <Product {...product} key={product.link} />
      ))}
    </Grid>
  );
};

export default AmazonAffiliate;
