import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Theme,
  Table,
  TableCell,
  TableRow,
  Box,
  Dialog,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Car } from "../graphql/queries/carqudb";
import CarInfoClusters from "../constants/CarInfoClusters";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import useTranslation from "../hooks/useTranslation";

import { isBefore } from "date-fns";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Skeleton } from "@mui/material";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 400,
    },
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const fromKwToCv = (kwValue: number): string =>
  Math.round(kwValue * 1.34102).toString();

const Alert: React.FC<AlertProps> = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ModalFeedback: React.FC<{
  openModal: boolean;
  handleCloseFeedback: (_: React.SyntheticEvent, reason?: string) => void;
}> = ({ openModal, handleCloseFeedback }) => {
  const classes = useStyles();
  return (
    <Dialog open={openModal} onClose={handleCloseFeedback}>
      <div className={classes.wrapper}>
        <iframe
          height="500"
          src="https://ldy5osymx4w.typeform.com/to/wJiiZAvS"
          title="¡Danos tu opinión!"
        ></iframe>
      </div>
    </Dialog>
  );
};

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const CarComponent: React.FC<{ car?: Car; loading: boolean }> = ({
  car,
  loading,
}) => {
  const [open, setOpen] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);

  useEffect(() => {
    if (car !== undefined) {
      const timer = setTimeout(() => setOpenModal(true), 2000);
      return () => clearTimeout(timer);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const t = useTranslation("components", "Car");

  const alertGenerator = (dateString: string): boolean =>
    isBefore(new Date(dateString), new Date("2014-12-01"));

  const handleClose = (
    _: Event | React.SyntheticEvent<any, Event>,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseFeedback = (_: React.SyntheticEvent, reason?: string) => {
    if (reason === "backdropClick") {
      setOpenModal(false);
    }
  };

  return (
    <>
      {car !== undefined && (
        <Snackbar
          open={open && alertGenerator(car.fec_matricula)}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <div>
            <Alert onClose={handleClose} severity="info">
              {t("snackbar")}
            </Alert>
          </div>
        </Snackbar>
      )}
      <Box mb={2}>
        <CarCard car={car} loading={loading} filter={CarInfoClusters.general} />
      </Box>
      <Box mb={2}>
        <h3>{t("seguro_online")}</h3>
        <ButtonGroup
          variant="outlined"
          color="primary"
          aria-label="contained primary button group"
        >
          <Button id="aff-insurance-mapfre">En Mapfre</Button>
          <Button id="aff-insurance-ld">En LineaDirecta</Button>
          <Button id="aff-insurance-mm">En MutuaMadrileña</Button>
        </ButtonGroup>
      </Box>
      <ModalFeedback
        openModal={openModal}
        handleCloseFeedback={handleCloseFeedback}
      ></ModalFeedback>
      <h3>{t("gestiones_online")}</h3>
      <Button
        variant="outlined"
        id="aff-credit"
        color="secondary"
        href="#contained-buttons"
      >
        {t("comprobar_credito")}
      </Button>
      <Button
        variant="outlined"
        id="aff-namechange"
        color="secondary"
        href="#contained-buttons"
      >
        {t("cambio_nombre")}
      </Button>
      <Box>
        <CarCard
          car={car}
          filter={CarInfoClusters.historic}
          loading={loading}
        />
      </Box>
      <Button
        variant="outlined"
        id="aff-carfax"
        color="secondary"
        href="#contained-buttons"
      >
        {t("comprobar_kilometros")}
      </Button>
    </>
  );
};

const CarCard: React.FC<{ car?: Car; filter: string[]; loading: boolean }> = ({
  car,
  filter,
  loading,
}) => {
  const t = useTranslation("components", "Car");
  return (
    <Card>
      <CardContent>
        <Table>
          {loading &&
            [...Array(4)].map((index: number) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <Skeleton variant="text" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton variant="text" />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          {car !== undefined &&
            filter.map((key, index) =>
              key !== "kw_itv"
                ? car[key] !== undefined &&
                  car[key] !== null && (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{t(key)}:</StyledTableCell>
                      {(car[key] as { descripcion: string })?.descripcion !==
                      undefined ? (
                        <StyledTableCell>
                          {(car[key] as { descripcion: string }).descripcion}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell>{car[key]}</StyledTableCell>
                      )}
                    </StyledTableRow>
                  )
                : car.kw_itv !== undefined &&
                  car.kw_itv !== null && (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{t("kw_itv")}:</StyledTableCell>
                      <StyledTableCell>
                        {fromKwToCv(car.kw_itv)}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
            )}
        </Table>
        {car === undefined && (
          <div>
            {t("coche_no_encontrado")}
            <a href="https://itv.com.es/numero-de-bastidor">
              {t("enlace_itv")}
            </a>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default CarComponent;
