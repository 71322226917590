import React from "react";
import MuiAlert from '@mui/material/Alert';

const Error: React.FC<{ error?: string }> = ({
  error = "Lo siento la pagina solicitada no existe.",
}) => {
  return (
    <MuiAlert elevation={6} variant="filled" color="error">
      {error}
    </MuiAlert>
  );
};

export default Error;
