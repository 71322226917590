import React from "react";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Box, Grid, AppBar, Toolbar, Fab, Zoom } from "@mui/material";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ServerError,
} from "@apollo/client";

import logo from "./assets/carqu-logo.svg";

import Button from "@mui/material/Button";

import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Sidebar from "./components/Sidebar";
import Terms from "./pages/Terms";

import Search from "./pages/Search";
import Error from "./pages/Error";
import Privacy from "./pages/Privacy";
import HomePage from "./pages/Home";

import TagManager from "react-gtm-module";

import useTranslation from "./hooks/useTranslation";

import ScrollToTop from "./hooks/scrollToTop";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const tagManagerArgs = {
  gtmId: "GTM-P9ZLSV7",
};

TagManager.initialize(tagManagerArgs);

const httpLink = createHttpLink({
  uri: "https://hasura-j5a6wgn37a-ey.a.run.app/v1/graphql",
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

const theme = createTheme();

function App() {
  const [redirectToError, setRedirectToError] = React.useState(false);
  const redirectOnServerError = onError(({ networkError }) => {
    if (
      (networkError &&
        networkError.name === "ServerError" &&
        (networkError as ServerError).statusCode >= 500) ||
      networkError?.name === "TypeError"
    ) {
      setRedirectToError(true);
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(redirectOnServerError).concat(httpLink),
    cache: new InMemoryCache(),
  });

  const useStyles = makeStyles((theme) => ({
    appBar: {
      top: "auto",
      bottom: 0,
    },
    fav: {
      position: "fixed",
      bottom: theme.spacing(10),
      right: theme.spacing(2),
    },
    appBarHeader: {
      top: "auto",
      bottom: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#000336",
    },
    toolbarHeader: {
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

  //const t = useTranslation("components", "Footer");
  const t = useTranslation("pages", "App");

  //const linkStyle = {
  //  textDecoration: "none",
  //  color: "white",
  //};

  const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <div>
            <AppBar position="static" className={classes.appBarHeader}>
              <img
                src={logo}
                height="50px"
                style={{ paddingLeft: 25 }}
                alt="carqu-logo"
              />
              <Toolbar className={classes.toolbarHeader}>
                <Button
                  href="/comprobar-bastidor-gratis"
                  style={{ textTransform: "capitalize", color: "white" }}
                >
                  {t("search_vin")}
                </Button>
                <Button style={{ textTransform: "capitalize", color: "white" }}>
                  {t("stats")}
                </Button>
              </Toolbar>
            </AppBar>
            <ApolloProvider client={client}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid>
                  <Sidebar />
                </Grid>
                <Grid>
                  <Box className="">
                    <ScrollToTop />
                    <Switch>
                      {redirectToError && (
                        <Error error="Ups! algo ha fallado, vuelva a intentarlo en unos minutos" />
                      )}
                      <Route path="/terms-and-conditions" component={Terms} />
                      <Route path="/privacy-policy" component={Privacy} />

                      {/* Search urls */}
                      <Route
                        path="/comprobar-bastidor-gratis"
                        component={Search}
                      />
                      <Route
                        path="/spanische-fahrgestellnummer"
                        component={Search}
                      />
                      <Route path="/check-spanish-vin" component={Search} />

                      <Route path="/" component={HomePage} />
                    </Switch>
                  </Box>
                </Grid>
              </Grid>
              <Zoom in={true}>
                <Fab
                  variant="extended"
                  className={classes.fav}
                  data-tf-slider="wJiiZAvS"
                  data-tf-width="550"
                  size="large"
                  color="secondary"
                >
                  {t("feedback")}
                </Fab>
              </Zoom>
            </ApolloProvider>
          </div>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
