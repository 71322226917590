import React from "react";
import { Hidden, Drawer, Fab, Divider, List, ListItem, ListItemText, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Menu } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      position: "fixed",
      top: 10,
      left: 10,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

const LinkList: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
        <List className={classes.nested}>
          <ListItem button component="a" href="/loqui">
            <ListItemText primary="Cualquier" />
          </ListItem>
        </List>
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

const Sidebar: React.FC = () => {
  const [mobileOpen, setMovileOpen] = React.useState<boolean>(false);
  const classes = useStyles();

  const handleDrawerToggle = () => setMovileOpen(!mobileOpen);

  //TODO: Enable Sidebar as soon as we have the next page
  const disabled = true;

  if (disabled) return null;

  return <>
    <nav>
      <Hidden smUp implementation="css">
        <Fab className={classes.menuButton} onClick={handleDrawerToggle}>
          <Menu />
        </Fab>
        <Drawer
          // container={container}
          variant="temporary"
          // anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          Responsive
          <LinkList />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer variant="permanent" open>
          No responsive
          <LinkList />
        </Drawer>
      </Hidden>
    </nav>
  </>;
};

export default Sidebar;
