import React from "react";
import { BuilderComponent, builder } from "@builder.io/react";

builder.init("7766694c700d435eb04dca05d603d0f6");

export const Home = () => {
  return (
  <>
    <BuilderComponent model="infobar" />
    <BuilderComponent model="page" />
    <BuilderComponent model="stats" />
    <BuilderComponent model="blog-articles" />
    <BuilderComponent model="footer" />
  </>
  );
};

export default Home;
