import React from "react";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";

import { Car } from "../graphql/queries/carqudb";
import { format, differenceInDays } from "date-fns";


const dateFormat = (dateString: string): string =>
  format(new Date(dateString), "dd/MM/yyyy");

const difference = (dateString1: string, dateString2: string): number =>
  differenceInDays(new Date(dateString1), new Date(dateString2));

const TransactionList: React.FC<{ car: Car; prevCar?: Car }> = ({
  car,
  prevCar,
}) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent>
        {car.fk_clave_tramite.descripcion}
        {prevCar && (
          <span>- {difference(prevCar.fec_tramite, car.fec_tramite)} días</span>
        )}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        {dateFormat(car.fec_tramite)} en {car.municipio}
      </TimelineContent>
    </TimelineItem>
  );
};

export default TransactionList;
