import { gql } from "@apollo/client";

export interface CarByVINVars {
  vin: string;
}

export interface Car {
  [key: string]: string | { descripcion: string } | number | undefined;
  //baja_telematica: string;
  //co2_itv?: string;
  //cod_posesion: string;
  //ind_baja_def: string;
  //ind_baja_temp: string;
  //ind_embargo?: string;
  //ind_precinto?: string;
  //ind_sustraccion?: string;
  id: number;
  bastidor_itv: string;
  categoria_vehiculo_electrico?: string;
  cilindrada_itv?: number;
  cod_tipo: string;
  cod_tutela: string;
  fec_matricula: string;
  fec_tramite: string;
  ind_nuevo_usado?: string;
  localidad_vehiculo: string;
  marca_itv: string;
  modelo_itv: string;
  municipio: string;
  nivel_emisiones_euro_itv: string;
  num_plazas_itv: number;
  num_transmisiones: number;
  persona_fisica_juridica: string;
  tipo_itv: string;
  fk_procedencia: { descripcion: string };
  fk_clave_tramite: { descripcion: string };
  fk_cod_propulsion: { descripcion: string };
  fk_servicio: { descripcion: string };

  carroceria?: string;
  cod_procedencia_itv?: string;
  cod_propulsion_itv?: string;
  kw_itv?: number;
  num_titulares?: string;
  renting?: string;
  servicio?: string;
}

export interface CarByVINData {
  carqudb: Car[];
}

// parameters removed: co2_itv, ind_embargo, ind_precinto, ind_sustraccion, cod_posesion, baja_telematica, ind_baja_temp, ind_baja_def

export const CarByVIN = gql`
  query CarByVIN($vin: String) {
    carqudb(
      where: { bastidor_itv: { _eq: $vin } }
      order_by: { fec_tramite: desc }
    ) {
      id
      bastidor_itv
      carroceria
      cilindrada_itv
      cod_procedencia_itv
      cod_propulsion_itv
      cod_tipo
      fec_matricula
      fec_tramite
      ind_nuevo_usado
      kw_itv
      marca_itv
      modelo_itv
      municipio
      nivel_emisiones_euro_itv
      num_plazas_itv
      num_titulares
      num_transmisiones
      persona_fisica_juridica
      renting
      servicio
      servicio
      tipo_itv
      fk_procedencia {
        descripcion
      }
      fk_clave_tramite {
        descripcion
      }
      fk_cod_propulsion {
        descripcion
      }
      fk_servicio {
        descripcion
      }
    }
  }
`;
