const clusters = {
  general: [
    "marca_itv",
    "modelo_itv",
    "fec_matricula",
    "cilindrada_itv",
    "kw_itv",
    "cod_propulsion_itv",
    "fk_cod_propulsion",
    "carroceria",
    "municipio",
    "num_transmisiones",
    "num_titulares",
    "persona_fisica_juridica",
  ],
  historic: [
    "num_transmisiones",
    "tipo_itv",
    "servicio",
    "ind_precinto",
    "ind_embargo",
    "ind_sustraccion",
    "renting",
    "ind_baja_def",
    "ind_baja_temp",
    "fk_servicio",
    "fk_procedencia",
  ],
};

export default clusters;
